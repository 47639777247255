import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import api from '../../api';
import CardActions from '@material-ui/core/CardActions';
import {Link} from "react-router-dom";
import logo from "../../assets/img/pdf.png";
import style from "../../assets/css/style";
import CircularProgress from '@material-ui/core/CircularProgress';
import {toast} from "react-toastify";
import translation from "../../translation/translate";

interface Props {
    classes: any;
    location: any;
    match: any;
    history:any;
}

interface State {
    user: any;
    media: any;
    arrayMedia: any;
    contentUrl: any;
    loading: boolean;
}

class RecapParticipation extends React.Component <Props, State> {
    state = {
        user: "",
        media: null,
        arrayMedia: [],
        contentUrl: [],
        loading: false,
    };

    componentDidMount() {
        this.mapUrlFunction();

        api.user.get_curr()
            .then(resp => {
                this.setState({user: resp.data})
            })
            .catch(err => null);
    }

    mapUrlFunction = () => {
        const urlImage = this.props.location.state.urlImage;
        let arrayMedia = [] as any;
        let contentUrl = [] as any;
        urlImage && urlImage.sellout_file && urlImage.sellout_file.length > 0 && urlImage.sellout_file.length != null && urlImage.sellout_file.forEach((medias:any) => {
            arrayMedia.push(medias.sellout_file && medias.sellout_file['@id']);
            contentUrl.push(medias.sellout_file && medias.sellout_file.contentUrl);
        });
        urlImage && urlImage.highlight_photo && urlImage.highlight_photo.length > 0 && urlImage.highlight_photo.length != null && urlImage.highlight_photo.forEach((medias:any) => {
            arrayMedia.push(medias.highlight_photo && medias.highlight_photo['@id']);
            contentUrl.push(medias.highlight_photo && medias.highlight_photo.contentUrl);
        });
        urlImage && urlImage.receipt_file && urlImage.receipt_file.length > 0 && urlImage.receipt_file.length != null && urlImage.receipt_file.forEach((medias:any) => {
            arrayMedia.push(medias.receipt_file && medias.receipt_file['@id']);
            contentUrl.push(medias.receipt_file && medias.receipt_file.contentUrl);
        });
        urlImage && urlImage.contract_file && urlImage.contract_file.length > 0 && urlImage.contract_file.length != null && urlImage.contract_file.forEach((medias:any) => {
            arrayMedia.push(medias.contract_file && medias.contract_file['@id']);
            contentUrl.push(medias.contract_file && medias.contract_file.contentUrl);
        });
        this.setState({contentUrl: contentUrl, arrayMedia: arrayMedia})
    }

    handleSubmit = () => {
        this.setState({loading: true});
        let filtered = this.state.arrayMedia.filter(function (el) {
            return el != null;
        });

        const newData : any = {
            "operation": this.props.location.state.participation,
            "declaredQuantity": parseInt(this.props.location.state.urlImage.declaredQuantity),
            "tickets": filtered,
        };
        if (this.props.location.state.edited) {
            newData.edited_id = this.props.location.state.edited;
        }
        api.participation.add_new(newData)
            .then(() => {
                this.setState({loading: true});
                this.props.history.push('/mes-demandes')
            })
            .catch(error => {
                toast.error(error.response.data['hydra:description']);
                this.setState({loading: false});
            })
    }

    render() {
        const {location} = this.props;
        const {user, contentUrl} : any= this.state;
        const type = location.state.type;
        const isImagePdf = (imageUrl: string) => /.pdf$/.test(imageUrl);
        return (
            <div style={{position: 'relative', minHeight: 'calc(100vh - 82px)', paddingTop: '85px'}}>
                <h1 style={style.title as any}>Récapitulatif de ma participation</h1>
                <Grid container justifyContent="center">
                    <Grid xs={7}>
                        <Card>
                            <CardContent style={{marginLeft: '70px'}}>
                                <Grid>
                                    <Grid xs={12} sm={12} md={12}>
                                        <h5 style={style.titleRecap}>Les informations de l'opération</h5>
                                        <Grid container>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Titre"
                                                        secondary={this.props.location.state.operation.title}
                                                    />
                                                </List>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Description"
                                                        secondary={this.props.location.state.operation.description}
                                                    />
                                                </List>
                                            </Grid>
                                            {process.env.REACT_APP_TEMPLATE_QUANTITY === 'true' && this.props.location.state.type !== "LocalOperation" &&
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Quantité déclarée"
                                                        secondary={this.props.location.state.urlImage.declaredQuantity}
                                                    />
                                                </List>
                                            </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12}>
                                        <h5 style={style.titleRecap}>Mes informations personnelles</h5>
                                        <Grid container>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary={translation[process.env.REACT_APP_CLIENT as string].label_cip_register }
                                                        secondary={user && user.companyId}
                                                    />
                                                </List>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Email"
                                                        secondary={user && user.email}
                                                    />
                                                </List>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Raison Social"
                                                        secondary={user && user.companyName}
                                                    />
                                                </List>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Adresse"
                                                        secondary={user && user.address + " " + user.city}
                                                    />
                                                </List>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Iban"
                                                        secondary={user && user.cachedIban}
                                                    />
                                                </List>
                                            </Grid>
                                            <Grid xs={12} sm={12} md={4}>
                                                <List>
                                                    <ListItemText
                                                        primary="Bic"
                                                        secondary={user && user.cachedBic}
                                                    />
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <h5 style={style.titleRecap}>Mes justificatifs</h5>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        {contentUrl.map((medias: string | null) =>
                                            <Grid item xs={6} sm={6} md={2}>
                                                {medias !== null &&
                                                <Card style={{marginRight: '10px'}}>
                                                    <CardContent>
                                                        {isImagePdf(medias) &&
                                                        <img
                                                            style={{height: '100px', cursor: "pointer"}}
                                                            onClick={() => window.open(`${process.env.REACT_APP_BACKEND_URL}/media/${medias}`, '_blank')}
                                                            src={logo} alt="..."/>

                                                        }
                                                        {!isImagePdf(medias) &&
                                                        <img
                                                            style={{height: '100px', width: '100%', cursor: "pointer"}}
                                                            onClick={() => window.open(`${process.env.REACT_APP_BACKEND_URL}/media/${medias}`, '_blank')}
                                                            src={`${process.env.REACT_APP_BACKEND_URL}/media/${medias}`}
                                                            alt="..."/>
                                                        }
                                                    </CardContent>
                                                </Card>
                                                }
                                            </Grid>
                                        )}
                                    </Grid>
                                    <CardActions>
                                        <Grid
                                            container
                                            justifyContent="center"
                                        >
                                            <Button style={style.btnModified as any} component={Link}
                                                    to={{
                                                        pathname: `/nouvelle-demande/${this.props.match.params.ope_id}`,
                                                        state: {type}
                                                    }}
                                            >Modifier ma participation
                                            </Button>
                                            {!this.state.loading &&
                                            <Button
                                                onClick={() => this.handleSubmit()}
                                                style={style.btnValidatePart as any}
                                            >Enregistrer ma participation
                                            </Button>
                                            }
                                            {this.state.loading &&
                                            <Button
                                                style={style.btnValidatePart as any}
                                            ><CircularProgress style={{color: 'white', height: '20px', width: '20px'}}/>
                                            </Button>
                                            }
                                        </Grid>
                                    </CardActions>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default (RecapParticipation)
