import React from 'react'
import {LockOpen, CreditCard, CardMembership} from '@material-ui/icons'
import Button from '@material-ui/core/Button';
import api from '../../api'
import {FieldGroup, FormBuilder, Validators, FormControl} from "react-reactive-form";
import {toast, ToastContentProps} from "react-toastify";
import ReactiveFormInputText from "../../shared/form/reactive-form-input-text.component";
import style from '../../assets/css/style';
import {PROFILE_FORM} from '../../shared/utils/variable'
import IBAN from 'iban';

interface Props {
    classes: any;
    data: any;
}

class IbanBicForm extends React.Component<Props> {
    ibanForm = FormBuilder.group({
        [PROFILE_FORM.IBAN]: ["",this.ibanValidator],
        [PROFILE_FORM.BIC]: ["", Validators.pattern('([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)')],
        [PROFILE_FORM.CURRENT_PWD]: ["", Validators.required],
    });

    handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        api.user.change_iban(this.ibanForm.value)
            .then(() => {
                toast.success('Votre profil a été mis à jour');
            })
            .catch((error: { response: { data: { [x: string]: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | ((props: ToastContentProps<{}>) => React.ReactNode) | null | undefined; }; }; }) => {
                toast.error(error.response.data['hydra:description']);
                console.error(error)
            })
    };

    ibanValidator(formControl: FormControl) {
        const iban = formControl.value;
        if (iban) {
            if (IBAN.isValid(iban)) {
                return null;
            }
            return {pattern: true};
        }
        return null;
    }

    render() {
        const { classes } = this.props;
        this.ibanForm.controls[PROFILE_FORM.IBAN].setValue(this.props.data.cachedIban);
        this.ibanForm.controls[PROFILE_FORM.BIC].setValue(this.props.data.cachedBic);
        return (
            <FieldGroup
                control={this.ibanForm}
                render={() => (
                    <form onSubmit={this.handleSubmit}>
                        <ReactiveFormInputText inputLabel={'Iban *'} inputName={PROFILE_FORM.IBAN} inputIcon={<CreditCard/>} type={'text'}/>
                        <ReactiveFormInputText inputLabel={'Bic *'} inputName={PROFILE_FORM.BIC}  inputIcon={<CardMembership/>} type={'text'}/>
                        <ReactiveFormInputText inputLabel={'Mot de passe *'} inputName={PROFILE_FORM.CURRENT_PWD} inputIcon={<LockOpen/>} type={'password'}/>
                        <div className={classes.center}>
                            <Button
                                style = {style.btnLogin}
                                type='submit'>
                                Enregistrer
                            </Button>
                        </div>
                    </form>
                )}
            />
        );
    }
}

export default IbanBicForm
